<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'qmhd_Questions' }">奇妙海岛题库</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑题目' : '添加题目'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                   
                    <el-form-item label="题目编号" prop="question_no">
                        <el-input v-model="infoForm.question_no"></el-input>
                    </el-form-item>

                    <el-form-item label="添加条件">
                        <div>
                            <el-table :data="infoForm.conditions" stripe style="width: 100%">
                                <el-table-column prop="condition_no" label="条件编号" width="140">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.$index+1" :disabled="true"></el-input>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column prop="condition" label="条件" width="400">
                                    <template scope="scope">
                                        <el-select  placeholder="选择条件" class="animal_select" v-model="scope.row.condition" @change="handleConditionChangeConfig(scope.row.condition,scope.$index)">
                                            <el-option v-for="item in conditions" :label="item.name" :value="item.name" :key="item.no"></el-option>
                                        </el-select>
                                </template>
                                </el-table-column>

                                <el-table-column prop="condition" label="选项1" width="400">
                                    <template scope="scope">
                                        <el-select  placeholder="选择条件" class="animal_select" v-model="scope.row.conditionTip1">
                                            <el-option v-for="item in animals" :label="item.name" :value="item.name" :key="item.no"></el-option>
                                        </el-select>
                                </template>
                                </el-table-column>

                                <el-table-column prop="condition" label="选项2" width="400">
                                    <template scope="scope">
                                        <el-select  placeholder="选择条件" class="animal_select" v-model="scope.row.conditionTip2">
                                            <el-option v-for="item in conditionTipArr2[scope.$index]" :label="item.name" :value="item.name" :key="item.no"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="condition" label="选项3" width="400">
                                    <template scope="scope">
                                        <el-select  placeholder="选择条件" class="animal_select" v-model="scope.row.conditionTip3">
                                            <el-option v-for="item in conditionTipArr3[scope.$index]" :label="item.name" :value="item.name" :key="item.no"></el-option>
                                        </el-select>
                                </template>
                                </el-table-column>

                                <el-table-column label="操作" width="70">
                                    <template scope="scope">
                                        <el-button
                                                size="mini"
                                                type="danger"
                                                icon="el-icon-delete" circle
                                                @click="conditionDelete(scope.$index, scope.row)">
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <el-button class="marginTop20" type="primary" @click="addCondition">新增条件</el-button>
                        </div>
                    </el-form-item>

                    <el-form-item label="设置答案">
                        <div>
                            <el-table :data="infoForm.answer" stripe style="width: 100%" :row-style="{height: '30px'}">
                                <el-table-column prop="pos_no" label="位置编号" width="140" >
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.pos_no" :disabled="true"></el-input>
                                    </template>
                                </el-table-column>
                                
                                <el-table-column prop="condition" label="选择动物" width="400">
                                    <template scope="scope">
                                            <el-select  placeholder="选择动物" class="animal_select" v-model="scope.row.name">
                                                <el-option v-for="item in animals" :label="item.name" :value="item.name" :key="item.no"></el-option>
                                            </el-select>
                                    </template>
                                </el-table-column>

                            </el-table>

                        </div>
                    </el-form-item>
                    
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" size="tiny">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                fruits:[
                    {
                        no:1,
                        name:'苹果',
                    },
                    {
                        no:2,
                        name:'柠檬',
                    },
                    {
                        no:3,
                        name:'橙子',
                    },
                    {
                        no:4,
                        name:'菠萝',
                    },
                    {
                        no:5,
                        name:'香蕉',
                    },
                    {
                        no:6,
                        name:'草莓',
                    }
                ],
                floors:[
                    {
                        no:1,
                        name:'低',
                    },
                    {
                        no:2,
                        name:'中',
                    },
                    {
                        no:3,
                        name:'高'
                    }
                ],
                
                floors:[
                    {
                        no:1,
                        name:'低',
                    },
                    {
                        no:2,
                        name:'中',
                    },
                    {
                        no:3,
                        name:'高'
                    }
                ],
                animals:[
                    {
                        no:1,
                        name:'熊猫',
                    },
                    {
                        no:2,
                        name:'猴子',
                    },
                    {
                        no:3,
                        name:'狮子',
                    },
                    {
                        no:4,
                        name:'大象',
                    },
                    {
                        no:5,
                        name:'老虎',
                    },
                    {
                        no:6,
                        name:'小猫',
                    },
                    {
                        no:7,
                        name:'小猪',
                    },
                    {
                        no:8,
                        name:'乌龟',
                    },
                    {
                        no:9,
                        name:'河马',
                    },
                ],
                conditions:[
                    {
                        name:"吃一",
                        no:1,
                    },
                    {
                        name:"不吃一",
                        no:2,
                    },
                    {
                        name:"吃同种水果",
                        no:3,
                    },
                    {
                        name:"不吃同种水果",
                        no:4,
                    },
                    {
                        name:"相邻",
                        no:5,
                    },
                    {
                        name:"不相邻",
                        no:6,
                    },
                    {
                        name:"高",
                        no:7,
                    },
                    {
                        name:"二在同层",
                        no:8,
                    },
                    {
                        name:"在某一层",
                        no:9,
                    },
                    {
                        name:"不在某层",
                        no:10,
                    },
                    {
                        name:"吃二",
                        no:11,
                    },
                    {
                        name:"不吃二",
                        no:12,
                    },
                    {
                        name:"三在同层",
                        no:13,
                    }
                ],
                conditionTipArr2:[],
                conditionTipArr3:[],
                infoForm: {
                    question_no: "",
                    conditions: [
                        {
                            condition_no:1,//条件编号
                            conditionTip1:'熊猫',//条件内容
                            conditionTip2:'',//条件内容
                            conditionTip3:'',//条件内容
                        }
                    ],
                    answer:[
                        {
                            pos_no:1,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:2,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:3,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:4,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:5,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:6,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:7,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:8,//位置编号
                            name:'熊猫'
                        },
                        {
                            pos_no:9,//位置编号
                            name:'熊猫'
                        }
                    ],
                    // is_index: false,
                }
            }
        },
        
        methods: {
           
            addCondition() {
                var length = this.infoForm.conditions.length+1;
                let ele = {
                    condition_no:1,//条件编号
                    conditionTip1:'熊猫',//条件内容
                    conditionTip2:'',//条件内容
                    conditionTip3:'',//条件内容
                }

                this.conditionTipArr2.push([]);
                this.conditionTipArr3.push([]);

                this.infoForm.conditions.push(ele);
            },
            conditionDelete(index, row) {
                this.infoForm.conditions.splice(index, 1);
            },
            findConditionItem(value)
            {
                for(var item of this.conditions)
                {
                    if(item.name == value)
                    {
                        return item;
                    }
                }
                return null;
            },
            handleConditionChangeConfig(v,index)
            {
                var item = this.findConditionItem(v);
                if(item)
                {
                    if(item.no == 1 || item.no == 2 )
                    {
                        this.conditionTipArr2[index] = this.fruits;
                        this.conditionTipArr3[index]  = [];

                        this.infoForm.conditions[index].conditionTip3 = '';
                        this.infoForm.conditions[index].conditionTip2 = this.conditionTipArr2[index][0].name;
                    }
                    else if(item.no >= 3 && item.no <= 8 )
                    {
                        this.conditionTipArr2[index] = this.animals;
                        this.conditionTipArr3[index]  = [];

                        this.infoForm.conditions[index].conditionTip3 = '';
                        this.infoForm.conditions[index].conditionTip2 = this.conditionTipArr2[index][0].name;
                    }
                   else if(item.no == 9 || item.no == 10 )
                    {
                        this.conditionTipArr2[index] = this.floors;
                        this.conditionTipArr3[index]  = [];

                        this.infoForm.conditions[index].conditionTip3 = '';
                        this.infoForm.conditions[index].conditionTip2 = this.conditionTipArr2[index][0].name;
                    }
                    else if(item.no == 11 || item.no == 12 )
                    {
                        this.conditionTipArr2[index] = this.fruits;
                        this.conditionTipArr3[index]  = this.fruits;

                        this.infoForm.conditions[index].conditionTip3 =  this.conditionTipArr3[index][0].name;
                        this.infoForm.conditions[index].conditionTip2 = this.conditionTipArr2[index][0].name;
                    }
                    else if(item.no == 13 )
                    {
                        this.conditionTipArr2[index] = this.animals;
                        this.conditionTipArr3[index] = this.animals;

                        this.infoForm.conditions[index].conditionTip3 =  this.conditionTipArr3[index][0].name;
                        this.infoForm.conditions[index].conditionTip2 = this.conditionTipArr2[index][0].name;
                    }
                }
            },
            InitConditionChangeConfig(v,index)
            {
                var item = this.findConditionItem(v);
                if(item)
                {
                    if(item.no == 1 || item.no == 2 )
                    {
                        this.conditionTipArr2[index] = this.fruits;
                        this.conditionTipArr3[index]  = [];

                    }
                    else if(item.no >= 3 && item.no <= 8 )
                    {
                        this.conditionTipArr2[index] = this.animals;
                        this.conditionTipArr3[index]  = [];

                    }
                   else if(item.no == 9 || item.no == 10 )
                    {
                        this.conditionTipArr2[index] = this.floors;
                        this.conditionTipArr3[index]  = [];

                    }
                    else if(item.no == 11 || item.no == 12 )
                    {
                        this.conditionTipArr2[index] = this.fruits;
                        this.conditionTipArr3[index]  = this.fruits;
                    }
                    else if(item.no == 13 )
                    {
                        this.conditionTipArr2[index] = this.animals;
                        this.conditionTipArr3[index] = this.animals;
                    }
                }
            },

            goBackPage() {
                this.$router.go(-1);
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        
                        var binfo ={
                            question_no:this.infoForm.question_no,
                            conditions:this.infoForm.conditions,
                            answer:this.infoForm.answer
                        }

                        console.log(binfo);
                        console.log(this.infoForm);
                
                        if(!!this.infoForm._id)
                        {
                            this.axios.post('dayquestion/udpateQMHDQuesioion',
                            {
                                questionInfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('dayquestion/addQMHDQuestion',
                            {
                                questionInfo: binfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '保存成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '保存失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            getInfo() {
                if (!this.infoForm._id) {
                    return false
                }
                return;
                //加载商品详情
                let that = this
                this.axios.get('qmhd/getQuestionInfo', {
                    params: {
                        id: that.infoForm.id
                    }
                }).then((response) => {
                    let resInfo = response.data.data;
                    let goodsInfo = resInfo.info;
                    that.infoForm = goodsInfo;
                })
            },
            recoverFromServer()
            {
        
                    var conditions = this.infoForm.conditions;
                    for(var i = 0;i!= conditions.length;i++)
                    {
                        var newArr1 = [];
                        var newArr2 = [];
                        this.conditionTipArr2.push(newArr1);
                        this.conditionTipArr3.push(newArr2);

                        this.InitConditionChangeConfig(conditions[i].condition,i);
                    }
              
            }
        },
        components: {
        },
        computed: {
        },
        mounted() {
            if(!!this.$route.query.questionInfo)
            {
                this.infoForm = this.$route.query.questionInfo ;
                this.recoverFromServer();
                console.log(this.infoForm);
                //this.getInfo();
            }
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }


    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .hidden {
        display: none;
    }
    .animal_select
    {

    }
    .animal_select  .el-input__inner{
        height: 30px;
    }
    /* 下面设置右侧按钮居中 */
    .animal_select  .el-input__suffix {
        top: 5px;
    }
    .animal_select   .el-input__icon {
        line-height: inherit;
    }
    .animal_select  .el-input__suffix-inner {
        display: inline-block;
    }
</style>
